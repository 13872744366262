import React, { useEffect, useRef, useState } from "react";
import "./Email.css";
import { useGetContactsQuery } from "../../Api/api";
import emailjs from "@emailjs/browser";

const Email = () => {
  const form = useRef();
  const { data: contacts, isFetching } = useGetContactsQuery();
  const [contactsDetails, setContactDetails] = useState(contacts);
  const [formValid, setFormValid] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setContactDetails(contacts);
  }, [contactsDetails, contacts]);

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (result) => {
            console.log(result.text);
            setFormSubmitted(true);
          },
          (error) => {
            console.log(error.text);
            setFormValid(false);
            setFormSubmitted(true);
          }
        );
      e.target.reset();
    } else {
      setFormValid(false);
      setFormSubmitted(true);
    }
  };

  const validateForm = () => {
    // Ajoutez vos règles de validation ici
    // Par exemple, assurez-vous que les champs obligatoires sont remplis
    const name = form.current.elements.user_name.value;
    const email = form.current.elements.user_email.value;
    const subject = form.current.elements.subject.value;
    const message = form.current.elements.message.value;

    return name && email && subject && message;
  };

  return (
    <>
      <div className="reachme-container">
        <div className="reachme-title2">
          <h2>Que ce soit pour une question ou une demande précise</h2>
          <h3>Contactez moi</h3>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="reachme-title">
              <div className="row">
                {contactsDetails &&
                  contactsDetails.map((details) => (
                    <div className="contact-info" key={details.id}>
                      <div className="contact-details">
                        <i className={details.icon}></i>
                        <div className="contact-mi">
                          <h4 className="icon-name">{details.contact_name}:</h4>
                          <p className="d-name">
                          <a href={details.contact_info} target="_blank" rel="noopener noreferrer">
                          {details.contact_info}
                           </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-6 email-me container">
            <form
              action=""
              className="contact-form"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="row">
                <div className="col-md-12 mb-3 hire-me-title"></div>
                <div className="col-md-6">
                  <input
                    type="text"
                    name="user_name"
                    id=""
                    placeholder="Entrez votre nom"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    name="user_email"
                    id=""
                    placeholder="Entrez votre email"
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    name="subject"
                    id=""
                    placeholder="Sujet"
                  />
                </div>
                <div className="col-md-12 mb-2">
                  <textarea
                    name="message"
                    id=""
                    cols="60"
                    rows="8"
                    placeholder="Votre message"
                  ></textarea>
                  <button className="hire-btn" type="submit">
                    Envoi
                  </button>
                  {formSubmitted && (
                    <p style={{ color: formValid ? "green" : "red" }}>
                      {formValid
                        ? "Message envoyé avec succès !"
                        : "Erreur pendant l'envoi du message. S'il vous plait réessayer plus tard."}
                    </p>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Email;
